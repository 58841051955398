import styled from "styled-components";

export const styleClasses = {
  overlay: "overlay",
  productList: "productList",
  progressDiv: "progressDiv",
};

export const StyledDiv = styled("div")<{ fontSize?: string }>(
  ({ theme, fontSize }) => ({
    [`&.${styleClasses.overlay}`]: {
      position: "fixed",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 99999,
      cursor: "pointer",
    },
    [`&.${styleClasses.productList}`]: {
      height: "100%",
      marginTop: "10px",
      fontSize: fontSize,
    },
    [`&.${styleClasses.progressDiv}`]: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      rowGap: "10px",
    },
  }),
);
